var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pt-3 pb-6 px-6"},[_c('v-text-field',{staticClass:"mb-3",attrs:{"label":"Поиск по должности","hide-details":"","clearable":"","outlined":"","readonly":"","color":"primary","append-icon":"mdi-magnify","dense":""}}),_c('div',[(_vm.loading)?[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"80px"}},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[_c('v-expansion-panels',{attrs:{"flat":"","accordion":"","multiple":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((_vm.services),function(service){return _c('v-expansion-panel',{key:service.id,staticClass:"panel"},[_c('v-expansion-panel-header',{staticClass:"pa-3",staticStyle:{"min-height":"48px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(service.data.loaded === false)?_c('v-progress-circular',{attrs:{"color":"primary","size":24,"indeterminate":""}}):_vm._e()]},proxy:true}],null,true)},[_c('v-row',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"text--text font-weight-500"},[_vm._v(_vm._s(service.name)+" ")])])],1),(service.data?.info)?_c('v-expansion-panel-content',[(_vm.mainData.type === 2)?_c('doljnost',{attrs:{"styleType":1,"dense":"","data":service.data?.info,"object":{ id: _vm.objectId }}}):[_c('coefficient',{staticClass:"mb-4",attrs:{"coef":service.data.info.coefficient,"service":service,"entity":{
                  type: 'doljnost',
                  id: service.data.info.external_tariff_data.doljnost_id,
                  object_id: _vm.objectId,
                }},on:{"changeEntity":_vm.changeEntity}}),_c('div',{staticClass:"pa-3 borderBlock"},[(service.data.info.contract_data)?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"mb-2 font-weight-700 text--text"},[_vm._v("Договор")]),_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',[_c('v-icon',{attrs:{"color":"textGray","size":"24"}},[_vm._v("mdi-clipboard-text-outline")]),_c('span',{staticClass:"ml-2 text--text font-weight-500"},[_vm._v(_vm._s(_vm.convertData( service.data.info.contract_data.date_from ))+" — "+_vm._s(_vm.convertData(service.data.info.contract_data.date_to)))])],1),_c('div',[_c('v-btn',{attrs:{"color":"#EDF5FD","large":"","elevation":"0"},on:{"click":function($event){return _vm.download(service.data.info.contract_data.file)}}},[_c('v-icon',{attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-file-download-outline")]),_c('span',{staticClass:"primary--text"},[_vm._v("Скачать")])],1),_c('historyChange',{attrs:{"id":service.data.info.external_tariff_data.id},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"ml-2",attrs:{"color":"#EDF5FD","large":"","elevation":"0"},on:{"click":on}},[_c('v-icon',{attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-cog-clockwise")]),_c('span',{staticClass:"primary--text"},[_vm._v("Изменения")])],1)]}}],null,true)})],1)])],1):_vm._e(),(service.data.info.external_tariff_data)?_c('div',[_c('div',{staticClass:"mb-2 font-weight-700 text--text"},[_vm._v(" Внешний тариф ")]),_c('v-row',[_c('div',{staticClass:"rate"},[_c('div',{staticClass:"text--text font-weight-500 mb-1"},[_vm._v("База")]),_c('v-btn',{attrs:{"color":"primary","large":""}},[_vm._v(_vm._s(service.data.info.external_tariff_data.external_rate)+" ")])],1)])],1):_vm._e(),(service.data.info.active_coefficient)?_c('div',[_c('div',{staticClass:"mb-2 font-weight-700 text--text"},[_vm._v(" Внутренний тариф ")]),_vm._l(([
                      'Аванс | Зарплата',
                      'Ежедневная',
                    ]),function(block,blockIndex){return _c('div',{key:blockIndex,staticClass:"block"},[_c('div',{staticClass:"text-center mb-2"},[_vm._v(_vm._s(block))]),(
                        service.data.info.active_coefficient[blockIndex + 1]
                          .id
                      )?[_c('v-row',{staticClass:"justify-space-between"},[_vm._l((_vm.rates),function(item,index){return _c('div',{key:index,staticClass:"rate"},[_c('div',{staticClass:"text--text font-weight-500 mb-1 d-flex justify-space-between"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-btn',{attrs:{"color":"primary","large":"","outlined":""}},[_vm._v(_vm._s(service.data.info?.active_coefficient[ blockIndex + 1 ][item.key])+" ")])],1)}),_c('div',{staticClass:"rate mb-2"},[_c('div',{staticClass:"text--text font-weight-500 mb-1"},[_vm._v(" Инд. ")]),_c('changeMenu',{attrs:{"type":"ind"},on:{"changeEntity":(e) =>
                                _vm.changeEntity({
                                  data: e,
                                  type: 'ind',
                                  service: {
                                    data: service,
                                    index: blockIndex + 1,
                                  },
                                })},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"primary","large":""}},on),[_vm._v("- ")])]}}],null,true)})],1)],2),_c('coefficient',{attrs:{"service":service,"type_id":blockIndex + 1,"entity":{
                          type: 'tariff',
                          id: service.data.info.active_coefficient[
                            blockIndex + 1
                          ].id,
                          single: blockIndex + 1,
                        },"info":{
                          date_from:
                            service.data.info.active_coefficient[
                              blockIndex + 1
                            ].date_from,
                          date_to:
                            service.data.info.active_coefficient[
                              blockIndex + 1
                            ].date_to,
                        },"coef":service.data.info.active_coefficient[blockIndex + 1]
                            .coefficient},on:{"changeEntity":_vm.changeEntity}})]:[_c('div',{staticClass:"textGray--text text-center mt-2"},[_vm._v(" Тарифный план в ведомости отсутствует ")])]],2)})],2):_vm._e()])]],2):_vm._e(),(service.data.info && service.data.loaded === false)?_c('div',{staticClass:"loadingPlug"}):_vm._e()],1)}),1)]],2),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_vm._v("Закрыть")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }