import SwitchDefault from '@/components/Switch/default/index.vue'
import Vue, { onMounted, nextTick, computed, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import Pact from './../pact/index.vue'
import Zone from './../zone/index.vue'
import useRequest from '@/compositions/useRequest'
import { useRouter, useRoute } from 'vue-router/composables'
import parserTarif from '@/pages/object/config/form-object-parser'
import territoryAdd from '@/pages/x5territories/config/form-territories-add-edit'
import zoneAdd from '@/pages/x5zone/config/custom-zone-add-edit'
import Detail from '@/components/Table/detail/index.vue'
import Autocomplete from '@/components/Autocomplete/default'
import FormDefault from '@/components/Form/default/index.vue'
import Coefficient from './../coefficient'
import Tarif from './../tarif'
import moment from 'moment/moment'
import history from '@/pages/object/config/custom-object-tarif/history'
import magnit from './../magnit'
import chigik from './../chigik'
import exPanel from './../exPanel'
import Popup from '@/components/Popup/index.vue'
import plug from './../plug'
// import FormOutput from '@/components/Form/output/correct/index.vue'

export default {
  name: 'Contract',
  props: {},
  components: {
    SwitchDefault,
    Pact,
    Zone,
    Detail,
    Autocomplete,
    FormDefault,
    Coefficient,
    history,
    Tarif,
    exPanel,
    Popup,
    magnit,
    plug,
    chigik,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(true)
    const types = ref([])
    const parser = ref(false)
    const typeRef = ref(null)
    const zoneRef = ref(null)
    const pactRef = ref(null)
    // const expansion = ref([])

    const switchBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Зона', value: 1 },
    ]

    const formTemplate = {
      isShow: false,
      component: null,
      type: null,
      entity: '',
      zone: null,
      contract: null,
    }
    const form = ref(_.cloneDeep(formTemplate))

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: (data) =>
        store.dispatch('list/get', [
          { alias: 'agreement_dictionary', filter: [] },
        ]),
    })

    const refresh = (territory) => {
      if (form.value.entity === 'territory') {
        refreshType()
      } else if (form.value.entity === 'zone') {
        refreshTerritory({ type: form.value.type, territory })
      } else if (form.value.entity === 'contract') {
        refreshTerritory({ type: form.value.type, territory })
      }
    }

    const getTypes = async () => {
      const response = await store.dispatch(
        'form/get',
        'get/object/contract/types'
      )
      response.data.forEach((item) => {
        Object.entries({
          loaded: null,
          loading: false,
          activeTerritories: [],
          active: [0],
          items: [],
          lastTarget: 0,
          docType: 0,
        }).forEach(([key, value]) => {
          Vue.set(item, key, value)
        })
      })
      types.value = response.data
      loading.value = false
    }

    const getTerritoriesRequest = async (item) => {
      return await store.dispatch('form/get', `get/territories/${item.id}`)
    }

    const changeTerritory = ({ index, btn, type }) => {
      if (btn === 'shift') {
        if (type.active.includes(index)) {
          if (type.lastTarget > index) {
            for (
              let i = type.lastTarget;
              i >= index && type.active.length > 1;
              i--
            ) {
              Vue.set(
                type,
                'active',
                type.active.filter((x) => x !== i)
              )
            }
          } else {
            for (
              let i = type.lastTarget;
              i <= index && type.active.length > 1;
              i++
            ) {
              Vue.set(
                type,
                'active',
                type.active.filter((x) => x !== i)
              )
            }
          }
        } else {
          if (type.lastTarget > index) {
            for (let i = type.lastTarget; i >= index; i--) {
              if (!type.active.includes(i)) {
                Vue.set(type, 'active', [...type.active, i])
              }
            }
          } else {
            for (let i = type.lastTarget; i <= index; i++) {
              if (!type.active.includes(i)) {
                Vue.set(type, 'active', [...type.active, i])
              }
            }
          }
        }
      } else if (btn === 'ctrl') {
        if (type.active.includes(index) && type.active.length > 1) {
          Vue.set(
            type,
            'active',
            type.active.filter((x) => x !== index)
          )
        } else {
          Vue.set(type, 'active', [...type.active, index])
        }
      } else {
        Vue.set(type, 'active', [index])
      }
      type.lastTarget = index
      if (type.items.length) getContracts({ type })
    }

    const changeDoc = (type, val) => {
      type.docType = val
      type.activeTerritories = []
      if (type.items.length) getContracts({ type })
    }

    const getContracts = async ({ type, refreshId = 0, refresh = false }) => {
      let spliceIndex
      const activeId = type.active.reduce((acc, item) => {
        acc.push(type.items[item].id)
        return acc
      }, [])
      Vue.set(
        type,
        'activeTerritories',
        type.activeTerritories.filter((item) => activeId.includes(item.id))
      )
      if (refresh) {
        type.activeTerritories = []
      }
      if (refreshId) {
        spliceIndex = type.activeTerritories.findIndex(
          (x) => x.id === refreshId
        )
        type.activeTerritories.splice(spliceIndex, 1)
      }
      type.loading = true
      const request = activeId.reduce(
        (acc, typeId, typeIndex) => {
          if (type.activeTerritories.some((x) => x.id === typeId) && !refresh)
            return acc
          acc.data.push(
            store.dispatch('form/getParams', {
              url: `get/${type.docType === 0 ? 'contract' : 'zones'}/${typeId}`,
            })
          )
          // acc.i.push(typeIndex)
          acc.id.push(typeId)

          return acc
        },
        { data: [], i: [], id: [] }
      )
      await Promise.all(request.data).then((response) => {
        type.loading = false
        response.forEach((item, index) => {
          const territory = type.items.find((x) => x.id === request.id[index])
          item.data.forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', [])
          })
          const obj = {
            coefficient: territory.coefficient,
            have_individual: territory.have_individual,
            name: territory.name,
            id: territory.id,
            items: item.data,
          }
          if (refreshId) {
            type.activeTerritories.splice(spliceIndex, 0, obj)
          } else {
            type.activeTerritories.push(obj)
          }
        })
      })
    }

    const newContractTemplate = {
      label: null,
      type: null,
    }
    const newContract = ref(_.cloneDeep(newContractTemplate))
    const closeDialog = () => {
      form.value = _.cloneDeep(formTemplate)
      newContract.value = _.cloneDeep(newContractTemplate)
    }
    const createNewContract = async () => {
      const response = await store.dispatch('form/create', {
        url: 'create/contract',
        body: {
          data: {
            name: newContract.value.label,
            territory_id: form.value.territory.id,
            type_id: newContract.value.type,
            type_object_id: 1,
          },
        },
      })
      if (response.code === 1) {
        refresh(form.value.territory)
        closeDialog()
      } else {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Название занято',
          timeout: 1000,
        })
      }
    }
    const addEntity = ({ type, zone, contract, territory }) => {
      if (zone) {
        form.value.component = _.cloneDeep(zoneAdd)
        form.value.entity = 'zone'
        form.value.zone = zone
        form.value.territory = territory
      } else if (contract) {
        form.value.component = null
        form.value.entity = 'contract'
        form.value.contract = contract
        form.value.territory = territory
      } else {
        form.value.component = _.cloneDeep(territoryAdd)
        form.value.entity = 'territory'
      }
      form.value.type = type
      form.value.isShow = true
    }

    const parserClone = ref()
    const openParser = ({
      territory,
      contract,
      version,
      contract_id,
      contract_type,
      type,
    }) => {
      parserClone.value = _.cloneDeep(parserTarif)
      const fields = parserClone.value.fields

      fields.find((x) => x.name === 'contract_object_types').value = type
      fields
        .find((x) => x.name === 'type_contract_id')
        .filter.push({
          alias: 'version',
          sendEmpty: true,
          value: version,
        })
      if (type === 1) {
        fields.find((x) => x.name === 'territory_id').value = territory.id
        fields.find((x) => x.name === 'agreement').value = contract.id
      } else if (type === 2) {
        fields.find((x) => x.name === 'magnit_agreement').value = contract.id
      } else if (type === 3) {
        fields.find((x) => x.name === 'chigik_agreement').value = contract.id
      }

      fields.forEach((item) => {
        item.readonly = true
      })

      parser.value = true
    }

    const autocompleteConfig = ref({
      items: [],
      label: 'Тип договора',
      selectOption: {
        text: 'name',
        value: 'id',
      },
    })

    const mainCoef = ref({})
    const getMainCoef = async () => {
      const response = await store.dispatch('form/get', 'get/coefficient/main')
      mainCoef.value = response.data
    }

    const getPanelData = (type) => {
      getContracts({ type })
    }

    const refreshType = () => {
      typeRef.value.expansion = []
      getTypes()
      getMainCoef()
    }

    const refreshTerritory = ({ type, territory }) => {
      getContracts({ type, refreshId: territory.id })
    }

    onMounted(async () => {
      getTypes()
      const list = await makeRequestList()
      autocompleteConfig.value.items = list.data.agreement_dictionary
      getMainCoef()
    })

    return {
      loading,
      types,
      switchBtn,

      // expansion,
      parser,
      parserClone,

      changeTerritory,
      changeDoc,
      openParser,

      form,
      refresh,
      addEntity,
      newContract,
      closeDialog,
      createNewContract,
      autocompleteConfig,
      mainCoef,

      getTerritoriesRequest,
      getPanelData,
      refreshType,
      refreshTerritory,
      typeRef,
      pactRef,
      zoneRef,
    }
  },
}
