import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import changeMenu from '../changeMenu'
import useRequest from '@/compositions/useRequest'
import history from './../history'

const coefficient = defineComponent({
  props: {
    coef: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: () => {},
    },
    service: {
      type: Object,
      default: () => {},
    },
    type_id: {
      type: Number,
      default: 0,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    changeMenu,
    history,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const convertData = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    return {
      convertData,
    }
  },
})
export default coefficient
