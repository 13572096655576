var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-card',{staticClass:"px-1 py-1",attrs:{"elevation":"0"}},_vm._l((_vm.magnitBtn),function(btn,btnIndex){return _c('v-btn',{key:btnIndex,class:[_vm.magnit.docType === btnIndex && 'btn--active', 'text-none'],attrs:{"min-height":"35px","elevation":"0","color":_vm.magnit.docType === btnIndex ? 'primary' : 'text',"text":_vm.magnit.docType !== btnIndex},on:{"click":function($event){_vm.magnit.docType = btn.value}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),1)],1),_c('v-row',{staticClass:"d-block"},[(_vm.magnit.loading)?[_c('div',{staticClass:"py-4 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[(_vm.magnit.docType === 0)?[_c('v-row',[_c('v-btn',{staticClass:"px-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","min-height":"46px","large":"","min-width":"30"},on:{"click":function($event){return _vm.addEntity({
                entity: {
                  type: 'contract',
                  refreshRequest: _vm.getMagnitType,
                },
                parent: {
                  type: 'type_object',
                  id: 2,
                },
              })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить договор")],1)],1),(_vm.magnit.items?.length)?[_c('v-row',[_c('exPanel',{attrs:{"outlined":"","request":_vm.getVersions,"items":_vm.magnit.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: doc }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":doc.name}},[_vm._v(" "+_vm._s(doc.name)+" ")])])])]}},{key:"content",fn:function({ item: doc }){return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){return _vm.addVersion({
                      item: doc,
                      refresh: doc.refresh,
                      component: 'version',
                    })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить версию")])],1),(doc.items?.length)?[_c('exPanel',{attrs:{"params":{ docType: 0, loading: true },"outlined":"","request":_vm.getVersions,"items":doc.items},on:{"getData":_vm.getVersionDocType},scopedSlots:_vm._u([{key:"header",fn:function({ item: version }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"primary--text mr-3"},[_vm._v("v "+_vm._s(version.version))]),_c('span',{staticClass:"text--text",attrs:{"title":version.name}},[_vm._v(_vm._s(version.name)+" ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"color":"textGray","size":"22"}},[_vm._v("mdi-calendar-clock-outline")]),_c('span',{staticClass:"textGray--text"},[_vm._v(_vm._s(_vm.convertDate(version.date_from))+" - "+_vm._s(_vm.convertDate(version.date_to)))])],1),_c('div',{staticClass:"d-flex ml-2"},[_c('v-btn',{staticClass:"px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(version.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)])]}},{key:"content",fn:function({ item: version }){return [_c('v-row',[_c('v-divider')],1),_c('v-row',[_c('v-card',{staticClass:"px-1 py-1",attrs:{"outlined":"","elevation":"0"}},_vm._l((_vm.contractBtn),function(btn,btnIndex){return _c('v-btn',{key:btnIndex,class:[
                              version.docType === btnIndex && 'btn--active',
                              'text-none',
                            ],attrs:{"min-height":"35px","elevation":"0","color":version.docType === btnIndex
                                ? 'primary'
                                : 'text',"text":version.docType !== btnIndex},on:{"click":function($event){version.docType !== btn.value &&
                                ((version.docType = btn.value),
                                _vm.getVersionDocType(version))}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),1)],1),_c('v-row',[_c('v-btn',{staticClass:"mb-2 text-none",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){version.docType === 0
                              ? _vm.addVersion({
                                  item: version,
                                  refresh: () => _vm.getVersionDocType(version),
                                  component: 'subversion',
                                })
                              : _vm.addVersion({
                                  item: version,
                                  refresh: () => _vm.getVersionDocType(version),
                                  component: 'application',
                                  contract: doc,
                                })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить "),_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(_vm.contractBtn[version.docType]?.name))])])],1)],1),(version.loading)?[_c('v-row',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[(version.items?.length)?_vm._l((version.items),function(app){return _c('v-row',{key:app.id,staticClass:"mt-2"},[(version.docType === 0)?[_c('div',{staticClass:"secondary--text text-cut d-flex align-center",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"primary--text mr-3"},[_vm._v("v "+_vm._s(app.version))]),_c('span',{staticClass:"text--text",attrs:{"title":version.name}},[_vm._v(_vm._s(app.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('v-btn',{staticClass:"px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(app.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)]:(version.docType === 1)?[_c('div',{staticClass:"secondary--text text-cut d-flex align-center",staticStyle:{"flex":"1","vertical-align":"center"}},[_c('span',{staticClass:"text--text",attrs:{"title":`Приложение №${app.num}`}},[_vm._v("Приложение №"+_vm._s(app.num)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[(
                                    app.type_id === 1 || app.type_id === 2
                                  )?_c('v-btn',{staticClass:"px-3 mx-1 text-none",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openParser', {
                                      contract: doc,
                                      version: app.id,
                                      type: 2,
                                    })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v("Загрузить тариф")],1):_vm._e(),_c('v-btn',{staticClass:"px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(app.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)]:_vm._e()],2)}):[_c('plug',{attrs:{"text":`${
                              version.docType === 0
                                ? 'Доп. соглашения'
                                : 'Приложения'
                            } не найдены`,"icon":"mdi-text-box"}})]]]}}],null,true)})]:[_c('plug',{attrs:{"icon":"mdi-text-box","text":"Версии не найдены"}})]]}}],null,false,2028086160)})],1)]:[_c('plug',{attrs:{"icon":"mdi-text-box","text":"Договора не найдены"}})]]:(_vm.magnit.docType === 1)?[_c('v-row',{staticClass:"btn-container mb-3"},_vm._l((_vm.magnit.items),function(magnitItem,magnitIndex){return _c('v-btn',{key:magnitItem.id,class:['btn', 'text-none'],attrs:{"min-height":"46px","elevation":"0","color":'primary',"outlined":!_vm.magnit.activeItems.find((x) => x.id === magnitItem.id)},on:{"click":[function($event){if(!$event.shiftKey)return null;return _vm.changeSelection({
                index: magnitIndex,
                btn: 'shift',
                item: magnitItem,
                items: _vm.magnit.items,
                activeItems: _vm.magnit.activeItems,
                entity: _vm.magnit,
                request: _vm.getActiveFillial,
              })},function($event){if(!$event.ctrlKey)return null;return _vm.changeSelection({
                index: magnitIndex,
                btn: 'ctrl',
                item: magnitItem,
                items: _vm.magnit.items,
                activeItems: _vm.magnit.activeItems,
                entity: _vm.magnit,
                request: _vm.getActiveFillial,
              })},function($event){if(!$event.metaKey)return null;return _vm.changeSelection({
                index: magnitIndex,
                btn: 'ctrl',
                item: magnitItem,
                items: _vm.magnit.items,
                activeItems: _vm.magnit.activeItems,
                entity: _vm.magnit,
                request: _vm.getActiveFillial,
              })},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeSelection({
                index: magnitIndex,
                btn: 'default',
                item: magnitItem,
                items: _vm.magnit.items,
                activeItems: _vm.magnit.activeItems,
                entity: _vm.magnit,
                request: _vm.getActiveFillial,
              })}]}},[_vm._v(" "+_vm._s(magnitItem.name)+" ")])}),1),_c('v-row',[_c('v-btn',{staticClass:"px-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","min-height":"46px","large":"","min-width":"30"},on:{"click":function($event){return _vm.addEntity({
                entity: {
                  type: 'district',
                  refreshRequest: _vm.getMagnitType,
                },
              })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить округ")],1)],1),_c('v-row',[_c('v-divider')],1),_vm._l((_vm.magnit.activeItems),function(district){return _c('div',{key:district.id,staticClass:"mt-3"},[_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":district.name}},[_vm._v(" "+_vm._s(district.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":district.have_individual,"requestData":{
                  entity: 'district',
                  id: district.id,
                }},on:{"refresh":_vm.getMagnitType}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                  entity: 'district',
                  id: district.id,
                },"coef":district.coefficient},on:{"refresh":_vm.getMagnitType}}),_c('v-btn',{staticClass:"px-2",attrs:{"min-width":"40","text":"","color":"primary"},on:{"click":function($event){return _vm.addEntity({
                    entity: {
                      type: 'district',
                      name: district.name,
                      id: district.id,
                      refreshRequest: _vm.getMagnitType,
                    },
                  })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)],1)]),_c('v-row',[_c('v-btn',{staticClass:"mb-2 text-none",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){_vm.addEntity({
                  entity: {
                    type: 'filial',
                    refreshRequest: () =>
                      _vm.getFillial({ item: district, refresh: true }),
                  },
                  parent: {
                    type: 'district',
                    id: district.id,
                  },
                })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить филиал")])],1)],1),(district.loaded)?[_c('v-row',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[(district.items?.length)?[_c('exPanel',{attrs:{"list":"magnit_subfilial","request":_vm.getSubfillial,"items":district.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: filial }){return [_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":filial.name}},[_vm._v(" "+_vm._s(filial.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{attrs:{"tarif":filial.have_individual,"requestData":{
                          entity: 'filial',
                          id: filial.id,
                        }},on:{"refresh":function($event){return _vm.getFillial({ item: district, refresh: true })}}}),_c('historyChange',{attrs:{"id":filial.id,"entity":'filial'},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"px-2 mr-3",attrs:{"min-width":"40","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return on.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-history")])],1)]}}],null,true)}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                          entity: 'filial',
                          id: filial.id,
                        },"coef":filial.coefficient},on:{"refresh":function($event){return _vm.getFillial({ item: district, refresh: true })}}}),_c('v-btn',{staticClass:"px-2",attrs:{"min-width":"40","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.addEntity({
                            entity: {
                              type: 'filial',
                              name: filial.name,
                              id: filial.id,
                              refreshRequest: () =>
                                _vm.getFillial({ item: district, refresh: true }),
                            },
                            parent: {
                              type: 'district',
                              id: district.id,
                            },
                          })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)],1)])]}},{key:"content",fn:function({ item: filial }){return [_c('v-row',[_c('v-btn',{staticClass:"mb-2 text-none",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){_vm.addEntity({
                          entity: {
                            type: 'subfilial',
                            refreshRequest: () => _vm.refreshPanel(filial),
                          },
                          parent: {
                            type: 'filial',
                            id: filial.id,
                          },
                        })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить подфилиал")])],1)],1),(filial.items?.length)?[_c('exPanel',{attrs:{"request":_vm.getFormat,"outlined":"","list":"magnit_format","items":filial.items,"params":{
                        activeItems: [],
                        lastTarget: 0,
                      }},on:{"getData":_vm.initFormat},scopedSlots:_vm._u([{key:"header",fn:function({ item: subfilial }){return [_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":subfilial.name}},[_vm._v(" "+_vm._s(subfilial.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":subfilial.have_individual,"requestData":{
                                entity: 'subfilial',
                                id: subfilial.id,
                              }},on:{"refresh":function($event){return _vm.refreshPanel(filial)}}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                                entity: 'subfilial',
                                id: subfilial.id,
                              },"coef":subfilial.coefficient},on:{"refresh":function($event){return _vm.refreshPanel(filial)}}}),_c('v-btn',{staticClass:"px-2",attrs:{"min-width":"40","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.addEntity({
                                  entity: {
                                    type: 'subfilial',
                                    name: subfilial.name,
                                    id: subfilial.id,
                                    refreshRequest: () =>
                                      _vm.refreshPanel(filial),
                                  },
                                  parent: {
                                    type: 'filial',
                                    id: filial.id,
                                  },
                                })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)],1)])]}},{key:"content",fn:function({ item: subfilial }){return [_c('v-row',{staticClass:"btn-container mb-3"},_vm._l((subfilial.items),function(formatItem,formatIndex){return _c('v-btn',{key:formatItem.id,class:['btn', 'text-none'],attrs:{"min-height":"46px","elevation":"0","color":'primary',"outlined":!subfilial.activeItems.find(
                                (x) => x.id === formatItem.id
                              )},on:{"click":[function($event){if(!$event.shiftKey)return null;return _vm.changeSelection({
                                index: formatIndex,
                                btn: 'shift',
                                item: formatItem,
                                items: subfilial.items,
                                activeItems: subfilial.activeItems,
                                entity: subfilial,
                                request: _vm.getActiveFormat,
                              })},function($event){if(!$event.ctrlKey)return null;return _vm.changeSelection({
                                index: formatIndex,
                                btn: 'ctrl',
                                item: formatItem,
                                items: subfilial.items,
                                activeItems: subfilial.activeItems,
                                entity: subfilial,
                                request: _vm.getActiveFormat,
                              })},function($event){if(!$event.metaKey)return null;return _vm.changeSelection({
                                index: formatIndex,
                                btn: 'ctrl',
                                item: formatItem,
                                items: subfilial.items,
                                activeItems: subfilial.activeItems,
                                entity: subfilial,
                                request: _vm.getActiveFormat,
                              })},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeSelection({
                                index: formatIndex,
                                btn: 'default',
                                item: formatItem,
                                items: subfilial.items,
                                activeItems: subfilial.activeItems,
                                entity: subfilial,
                                request: _vm.getActiveFormat,
                              })}]}},[_vm._v(" "+_vm._s(formatItem.name)+" ")])}),1),_c('v-row',[_c('v-divider')],1),_vm._l((subfilial.activeItems),function(format){return _c('div',{key:format.id,staticClass:"mt-3"},[_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":format.name}},[_vm._v(" "+_vm._s(format.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":format.have_individual,"requestData":{
                                  entity: 'format',
                                  id: format.id,
                                }},on:{"refresh":function($event){return _vm.getFormatItem({
                                    item: format,
                                    format: filial,
                                    refresh: true,
                                  })}}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                                  entity: 'format',
                                  id: format.id,
                                },"coef":format.coefficient},on:{"refresh":function($event){return _vm.getFormatItem({
                                    item: format,
                                    format: filial,
                                    refresh: true,
                                  })}}})],1)]),(format.loaded)?[_c('v-row',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[(format.items?.length)?[_c('v-row',[_c('exPanel',{attrs:{"request":_vm.getObject,"outlined":"","list":"active_doljnost_by_object","items":format.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: object }){return [_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":object.name}},[_vm._v(" "+_vm._s(object.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":object.have_individual,"requestData":{
                                            entity: 'object',
                                            id: object.id,
                                          }},on:{"refresh":function($event){return _vm.refreshPanel(object)}}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                                            entity: 'object',
                                            id: object.id,
                                          },"coef":object.coefficient},on:{"refresh":function($event){return _vm.refreshPanel(object)}}})],1)])]}},{key:"content",fn:function({ item: object }){return [(object.items?.length)?_c('v-row',[_c('div',{staticClass:"doljnostContainer"},_vm._l((object.items),function(doljnost){return _c('Doljnost',{key:doljnost.id,attrs:{"styleType":2,"data":doljnost,"object":object}})}),1)]):_c('plug',{attrs:{"icon":"mdi-map-clock","text":"Должности не найдены"}})]}}],null,true)})],1)]:[_c('plug',{attrs:{"icon":"mdi-text-box","text":"Объекты не найдены"}})]]],2)})]}}],null,true)})]:[_c('plug',{attrs:{"icon":"mdi-text-box","text":"Подфилиалы не найдены"}})]]}}],null,true)})]:[_c('plug',{attrs:{"icon":"mdi-text-box","text":"Филиалы не найдены"}})]]],2)})]:_vm._e()]],2),(_vm.form.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[(_vm.form.isShow)?_c('v-card',{staticClass:"pa-4"},[_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.form.entity.id ? 'Изменить' : 'Добавить'))]),_c('v-row',{staticClass:"mt-2"},[_c('v-text-field',{attrs:{"counter":_vm.form.maxCount,"label":"Название"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"loading":_vm.form.loading,"color":"primary","text":""},on:{"click":function($event){_vm.form.isShow = false}}},[_vm._v("Отменить")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.form.name || _vm.form.name.length > _vm.form.maxCount,"loading":_vm.form.loading,"color":"primary"},on:{"click":_vm.confirmChanges}},[_vm._v("Сохранить")])],1)],1):_vm._e()],1):_vm._e(),(_vm.dialog.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[(_vm.dialog.isShow)?[(_vm.dialog.component === 'version')?_c('versionChange',{attrs:{"version":_vm.dialog.doc,"tariff":false},on:{"close":function($event){_vm.dialog.isShow = false},"refreshItem":function($event){return _vm.dialog.refresh()}}}):_vm._e(),(_vm.dialog.component === 'subversion')?_c('subversionChange',{attrs:{"version":_vm.dialog.doc,"magnit":""},on:{"close":function($event){_vm.dialog.isShow = false},"refreshItem":function($event){return _vm.dialog.refresh()}}}):_vm._e(),(_vm.dialog.component === 'application')?_c('applicationChange',{attrs:{"version":_vm.dialog.doc,"contract":_vm.dialog.contract},on:{"close":function($event){_vm.dialog.isShow = false},"refreshItem":function($event){return _vm.dialog.refresh()},"openParser":(e) => _vm.$emit('openParser', e)}}):_vm._e()]:_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }