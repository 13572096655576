import Vue, {
  nextTick,
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'

export default defineComponent({
  props: {
    fillActive: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    request: {
      type: Function,
      default: null,
    },
    list: {
      type: String,
      default: '',
    },
    magnit: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    itemsParams: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const expansion = ref([])
    const changePanel = ({ item, index, refresh }) => {
      nextTick(async () => {
        if (props.magnit && (item.id === 2 || item.id === 12)) return
        if (props.request) {
          if (!refresh) {
            if (item.loaded !== true)
              expansion.value = _.without(expansion.value, index)
            if (item.loaded !== null) return
          }
          item.loaded = false
          const response = await props.request(item)
          let responseItems = response.data
          if (props.list) responseItems = response.data[props.list]

          const params = {
            loaded: null,
            items: null,
            ...props.itemsParams,
          }
          responseItems.forEach((item) => {
            Object.entries(params).forEach(([key, value]) => {
              Vue.set(item, key, value)
            })
          })

          if (props.params)
            Object.entries(props.params).forEach(([key, value]) => {
              Vue.set(item, key, value)
            })
          if (refresh) item.items = []
          nextTick(() => (item.items = responseItems))
          item.loaded = true
          if (!refresh) expansion.value.push(index)
          Vue.set(item, 'refresh', () => refreshItem(index))
        }
        nextTick(() => emit('getData', item))
      })
    }

    const refreshItem = (index) => {
      const item = props.items[index]
      item.loaded = null
      changePanel({ item, index, refresh: true })
    }

    return {
      expansion,
      changePanel,
    }
  },
})
