import Vue, { onMounted, computed, toRef, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'
import Datepicker from '@/components/Date/Default/index.vue'
import DropZone from '@/components/Dropzone/default/index.vue'
import { useRouter, useRoute } from 'vue-router/composables'
import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  checkboxField,
  dropZoneField,
  carouselField,
  docListField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import useForm from '@/compositions/useForm.js'
import useRequest from '@/compositions/useRequest'
import Autocomplete from '@/components/Autocomplete/default'

export default {
  name: 'Version-Dialog',
  props: {
    version: {
      type: Object,
      default: () => {},
    },
    contract: {
      type: Object,
      default: () => {},
    },
  },
  components: { DropZone, Autocomplete },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(true)

    const autocompleteConfig = ref({
      items: [],
      label: '',
      outlined: true,
      hideDetails: true,
      selectOption: {
        text: 'name',
        value: 'id',
      },
    })

    const { makeRequest: createForm } = useRequest({
      context,
      successMessage: 'Сохранено',
      request: async (params) => {
        const response = await store.dispatch(params.module, {
          url: params.url,
          body: {
            data: {
              ...params.formData,
              version_id: props.version.id,
            },
          },
        })
        if (response.code === 1) {
          emit('close')
          emit('refreshItem')
        }
        if (formData.prolongation_tarif) {
          emit('openParser', {
            contract: props.contract,
            version: response.id,
            type: 2,
          })
        }
      },
    })

    const getUnix = (val) => {
      return moment(val, 'YYYY-MM-DD').unix()
    }

    const { formData, fields, clickHandler } = useForm({
      form: {
        detail: true,
        lists: [],
        fields: [
          stringField({
            label: 'num',
            name: 'num',
            requestType: 'number',
            placeholder: '',
            value: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          selectField({
            label: 'type_id',
            name: 'type_id',
            alias: 'magnit_application_type',
            placeholder: '',
            class: [''],
            selectOption: {
              text: 'name',
              value: 'id',
            },
            items: [],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          checkboxField({
            label: 'prolongation_tarif',
            name: 'prolongation_tarif',
            value: false,
            notSend: true,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          dropZoneField({
            label: '',
            name: 'file',
            notPut: true,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
            options: {
              removeble: true,
              withoutSave: false,
              maxSize: 10,
              valueId: 'agreement',
              folder: 'agreement',
              name: '`agreement`',
              paramsForEmit: this,
              countFiles: 1,
            },
            value: [],
          }),
        ],
      },
      context,
      loading,
      createForm,
    })

    watch(
      () => formData,
      () => {
        if (formData.with_prolongation) {
          formData.prolongation_tarif = false
        } else {
          formData.prolongation_price = false
          formData.with_prolongation = false
        }
      },
      { deep: true }
    )

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: (data) =>
        store.dispatch('list/get', [
          { alias: 'magnit_application_type', filter: [] },
        ]),
    })

    onMounted(async () => {
      const list = await makeRequestList()
      autocompleteConfig.value.items = list.data.magnit_application_type
    })

    return {
      formData,
      clickHandler,
      fields,
      autocompleteConfig,

      getUnix,
    }
  },
}
