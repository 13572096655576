import Vue, {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import exPanel from './../exPanel'
import useRequest from '@/compositions/useRequest'
import Doljnost from './../doljnost'
import Popup from '@/components/Popup/index.vue'
import Coefficient from './../coefficient'
import Tarif from './../tarif'
import versionChange from './../pact/dialog'
import subversionChange from './../pact/version/dialog'
import plug from './../plug'
import historyChange from '@/pages/object/config/custom-object-tarif/historyChange'

export default defineComponent({
  props: {},
  components: {
    exPanel,
    Popup,
    Doljnost,
    Tarif,
    Coefficient,
    versionChange,
    subversionChange,
    historyChange,
    plug,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const dialogTemplate = {
      isShow: false,
      doc: null,
      refresh: null,
      component: null,
    }
    const dialog = ref(_.cloneDeep(dialogTemplate))
    const addVersion = ({ item, component, refresh, contract }) => {
      dialog.value.doc = item
      dialog.value.isShow = true
      dialog.value.refresh = refresh
      dialog.value.component = component
      dialog.value.contract = contract
    }

    const formTemplate = {
      isShow: false,
      name: '',
      loading: false,
      maxCount: 255,
      refreshRequest: null,
    }
    const form = ref(_.cloneDeep(formTemplate))
    const addEntity = ({ entity }) => {
      form.value = _.cloneDeep(formTemplate)
      form.value.isShow = true
      form.value.refreshRequest = entity.refreshRequest
    }
    const confirmChanges = async () => {
      form.value.loading = true
      try {
        const response = await store.dispatch(`form/create`, {
          url: 'create/contract',
          body: {
            data: {
              name: form.value.name,
              type_object_id: 12,
            },
          },
        })
        if (response.code === 1) {
          form.value.isShow = false
          form.value.refreshRequest()
          store.commit('notifies/showMessage', {
            color: 'success',
            content: 'Сохранено',
            timeout: 1000,
          })
        } else if (response.code === 2) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Ошибка сервера',
            timeout: 1000,
          })
        } else if (response.code === 3) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Невалидные данные',
            timeout: 1000,
          })
        } else if (response.code === 4) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 1000,
          })
        } else if (response.code === 5) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Уже существует',
            timeout: 1000,
          })
        }
      } finally {
        form.value.loading = false
      }
    }

    const chigikBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Регионы', value: 1 },
    ]

    // const contractBtn = [
    //   { name: 'Доп. соглашение', value: 0 },
    //   { name: 'Приложение', value: 1 },
    // ]

    let controller
    const getChigikType = async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      chigik.value.loading = true
      let response
      try {
        if (chigik.value.docType === 0) {
          response = await store.dispatch('form/getParams', {
            url: 'get/contract/chigik',
            params: {
              signal: controller.signal,
            },
          })
          response.data.forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          chigik.value.items = response.data
        } else if (chigik.value.docType === 1) {
          const list = 'contract_regions'
          response = await store.dispatch('form/update', {
            url: 'get/lists',
            body: [{ alias: list, filter: [] }],
            params: {
              signal: controller.signal,
            },
          })
          response.data[list].forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          chigik.value.items = response.data[list]
        }
      } catch (e) {
        return e
      }
      controller = undefined
      chigik.value.loading = false
    }

    const chigik = ref({
      items: [],
      loading: false,
      docType: 0,
      refresh: getChigikType,
    })

    const getCities = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'contract_city',
          filter: [
            {
              alias: 'region_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectsByCity = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'chigik_object_by_city',
          filter: [
            {
              alias: 'city_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getVersions = async (item) => {
      return await store.dispatch(
        'form/get',
        `get/contract/versions/${item.id}`
      )
    }

    const refreshPanel = (item) => {
      item.refresh()
    }

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    watch(
      () => chigik.value.docType,
      () => {
        getChigikType()
      },
      { immediate: true }
    )

    return {
      chigikBtn,
      chigik,
      dialog,

      refreshPanel,
      getChigikType,
      getCities,
      getObjectsByCity,
      getObject,
      getVersions,

      addVersion,

      convertDate,
      download,

      addEntity,
      form,
      confirmChanges,
    }
  },
})
