var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-card',{staticClass:"px-1 py-1",attrs:{"elevation":"0"}},_vm._l((_vm.chigikBtn),function(btn,btnIndex){return _c('v-btn',{key:btnIndex,class:[_vm.chigik.docType === btnIndex && 'btn--active', 'text-none'],attrs:{"min-height":"35px","elevation":"0","color":_vm.chigik.docType === btnIndex ? 'primary' : 'text',"text":_vm.chigik.docType !== btnIndex},on:{"click":function($event){_vm.chigik.docType = btn.value}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),1)],1),_c('v-row',{staticClass:"d-block"},[(_vm.chigik.loading)?[_c('div',{staticClass:"py-4 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:[(_vm.chigik.docType === 0)?[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){return _vm.addEntity({
              entity: {
                refreshRequest: _vm.getChigikType,
              },
            })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить договор")])],1),(_vm.chigik.items?.length)?[_c('exPanel',{attrs:{"outlined":"","request":_vm.getVersions,"items":_vm.chigik.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: doc }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":doc.name}},[_vm._v(" "+_vm._s(doc.name)+" ")])])])]}},{key:"content",fn:function({ item: doc }){return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"#EDF5FD","elevation":"0","block":""},on:{"click":function($event){return _vm.addVersion({
                    item: doc,
                    refresh: doc.refresh,
                    component: 'version',
                  })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#4E9EEE"}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"font-size-14 font-weight-400",staticStyle:{"text-transform":"none","color":"#4e9eee"}},[_vm._v("Добавить версию")])],1),(doc.items?.length)?_vm._l((doc.items),function(version){return _c('v-row',{key:version.id,staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"primary--text mr-3"},[_vm._v("v "+_vm._s(version.version))]),_c('span',{staticClass:"text--text",attrs:{"title":version.name}},[_vm._v(_vm._s(version.name)+" ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"color":"textGray","size":"22"}},[_vm._v("mdi-calendar-clock-outline")]),_c('span',{staticClass:"textGray--text"},[_vm._v(_vm._s(_vm.convertDate(version.date_from))+" - "+_vm._s(_vm.convertDate(version.date_to)))])],1),_c('div',{staticClass:"d-flex ml-2"},[_c('v-btn',{staticClass:"px-3 mx-1 text-none",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openParser', {
                          contract: doc,
                          version: version.version,
                          type: 3,
                        })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v("Загрузить тариф")],1),_c('v-btn',{staticClass:"px-0",attrs:{"elevation":"0","min-width":"40px","color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(version.file)}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)],1)])}):_c('plug',{attrs:{"text":"Версии не найдены"}})]}}],null,false,2539227337)})]:_c('plug',{attrs:{"text":"Договора не найдены"}})]:(_vm.chigik.docType === 1)?[(_vm.chigik.items?.length)?[_c('exPanel',{attrs:{"outlined":"","list":"contract_city","request":_vm.getCities,"items":_vm.chigik.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: region }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":region.name}},[_vm._v(" "+_vm._s(region.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":region.have_individual,"requestData":{
                      entity: 'chizhyk_region',
                      id: region.id,
                    }},on:{"refresh":() => region.refresh()}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                      entity: 'chizhyk_region',
                      id: region.id,
                    },"coef":region.coefficient},on:{"refresh":() => region.refresh()}})],1)])]}},{key:"content",fn:function({ item: region }){return [(region.items?.length)?[_c('exPanel',{attrs:{"outlined":"","list":"chigik_object_by_city","request":_vm.getObjectsByCity,"items":region.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: city }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":city.name}},[_vm._v(" "+_vm._s(city.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":city.have_individual,"requestData":{
                            entity: 'chizhyk_city',
                            id: city.id,
                          }},on:{"refresh":() => city.refresh()}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{
                            entity: 'chizhyk_city',
                            id: city.id,
                          },"coef":city.coefficient},on:{"refresh":() => city.refresh()}})],1)])]}},{key:"content",fn:function({ item: city }){return [(city.items?.length)?[_c('exPanel',{attrs:{"outlined":"","list":"active_doljnost_by_object","request":_vm.getObject,"items":city.items},scopedSlots:_vm._u([{key:"header",fn:function({ item: object }){return [_c('v-row',{staticClass:"justify-space-between align-center overflow-hidden"},[_c('div',{staticClass:"secondary--text text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":object.name}},[_vm._v(" "+_vm._s(object.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":object.have_individual,"requestData":{
                                  entity: 'chizhyk_object',
                                  id: object.id,
                                }},on:{"refresh":() => object.refresh()}})],1)])]}},{key:"content",fn:function({ item: object }){return [(object.items?.length)?_c('v-row',[_c('div',{staticClass:"doljnostContainer"},_vm._l((object.items),function(doljnost){return _c('Doljnost',{key:doljnost.id,attrs:{"styleType":1,"data":doljnost,"object":object}})}),1)]):_c('plug',{attrs:{"icon":"mdi-map-clock","text":"Должности не найдены"}})]}}],null,true)})]:_c('plug',{attrs:{"text":"Объекты не найдены"}})]}}],null,true)})]:_c('plug',{attrs:{"text":"Города не найдены"}})]}}],null,false,3366001153)})]:_c('plug',{attrs:{"text":"Регионы не найдены"}})]:_vm._e()]],2),(_vm.form.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[(_vm.form.isShow)?_c('v-card',{staticClass:"pa-4"},[_c('p',{staticClass:"text-h4"},[_vm._v("Добавить")]),_c('v-row',{staticClass:"mt-2"},[_c('v-text-field',{attrs:{"counter":_vm.form.maxCount,"label":"Название"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"loading":_vm.form.loading,"color":"primary","text":""},on:{"click":function($event){_vm.form.isShow = false}}},[_vm._v("Отменить")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.form.name || _vm.form.name.length > _vm.form.maxCount,"loading":_vm.form.loading,"color":"primary"},on:{"click":_vm.confirmChanges}},[_vm._v("Сохранить")])],1)],1):_vm._e()],1):_vm._e(),(_vm.dialog.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[(_vm.dialog.isShow)?[(_vm.dialog.component === 'version')?_c('versionChange',{attrs:{"version":_vm.dialog.doc,"type":3},on:{"close":function($event){_vm.dialog.isShow = false},"refreshItem":function($event){return _vm.dialog.refresh()},"openParser":(e) => _vm.$emit('openParser', e)}}):_vm._e()]:_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }