var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.info)?_c('v-tooltip',{attrs:{"z-index":"100","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4148488466)},[_c('span',[_vm._v(_vm._s(_vm.convertData(_vm.info.date_from))+" — "+_vm._s(_vm.convertData(_vm.info.date_to)))])]):_vm._e(),_c('span',{staticClass:"mr-2 textGray--text"},[_vm._v("Коэффициент: "+_vm._s(_vm.coef))]),_c('changeMenu',{attrs:{"type":"coef"},on:{"changeEntity":(e) =>
        _vm.$emit('changeEntity', {
          data: e,
          type: 'coef',
          service: {
            data: _vm.service,
            type_id: _vm.type_id,
          },
          segment: _vm.info ? true : undefined,
        })},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"ml-2 px-2",attrs:{"min-width":"30","elevation":"0","color":"primary","text":""}},on),[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v("mdi-cog-outline")])],1)]}}])}),_c('history',{staticClass:"ml-2",attrs:{"entity":_vm.entity}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }