import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import coefficient from './coefficient'
import changeMenu from './changeMenu'
import history from './history'
import historyChange from './historyChange'
import useRequest from '@/compositions/useRequest'
import doljnost from '@/components/Contract/doljnost'

const ObjectTarif = defineComponent({
  id: uuidv4(),
  components: {
    coefficient,
    history,
    historyChange,
    changeMenu,
    doljnost,
  },
  props: {
    mainData: {
      type: Object,
      default: () => {},
    },
  },
  path: 'edit',
  name: 'Тарифы',
  isShow: {
    value: true,
    condition: [
      {
        funcCondition: (ctx) => {
          if ([3, 4, 7, 19, 16].includes(ctx.store.state.user.permission_id)) {
            if (
              ctx.mainData.direction_json?.includes(2) &&
              [1, 2, 12].includes(ctx.mainData.type)
            )
              return true
          }
          return false
        },
      },
    ],
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const rates = [
      { name: 'База', key: 'base_rate' },
      { name: 'I', key: '1_rate' },
      { name: 'II', key: '2_rate' },
      { name: 'III', key: '3_rate' },
      { name: 'IV', key: '4_rate' },
    ]
    const expansion = ref([])
    const services = ref([])
    const loading = ref(false)
    const objectId = computed(() => Number(route.params.id))

    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: async (data) => {
        const response = await store.dispatch('list/get', data)
        return response
      },
    })

    const getService = async () => {
      loading.value = true
      const response = await makeRequestList([
        {
          alias: 'object_active_doljnost',
          filter: [
            {
              alias: 'object_id',
              value: [objectId.value],
            },
          ],
        },
      ])
      response.data.object_active_doljnost.forEach((item) => {
        Vue.set(item, 'data', {
          loaded: null,
          info: null,
        })
      })
      services.value = response.data.object_active_doljnost
      loading.value = false
    }

    const getTargetService = async (index, refresh = false) => {
      const service = services.value[index]
      if (!refresh) {
        if (service.data.loaded !== true)
          expansion.value = _.without(expansion.value, index)
        if (service.data.loaded !== null) return
      }
      service.data.loaded = false
      const response = await store.dispatch(
        'form/get',
        `get/tariff/object/${objectId.value}/${service.id}`
      )
      service.data.info = response.result
      service.data.loaded = true
      if (!refresh) expansion.value.push(index)
    }

    const changeEntity = async ({ data, type, service, segment }) => {
      let response
      const date_from = moment(data.date_from, 'YYYY.MM.DD').format(
        'YYYY-MM-DD'
      )
      const date_to = moment(data.date_to, 'YYYY.MM.DD').format('YYYY-MM-DD')
      service.data.data.loaded = false
      if (type === 'coef') {
        if (segment) {
          response = await store.dispatch('form/putForm', {
            url: `coefficient/change/tariff`,
            body: {
              data: {
                date_from,
                date_to,
                coefficient: Number(data.coef),
                tariff_id: service.data.data.info.external_tariff_data.id,
                type_id: service.type_id,
              },
            },
          })
        } else {
          response = await store.dispatch('form/putForm', {
            url: `coefficient/change/doljnost`,
            body: {
              data: {
                date_from,
                date_to,
                object_id:
                  service.data.data.info.external_tariff_data.object_id,
                coefficient: Number(data.coef),
                doljnost_id: service.data.id,
              },
            },
          })
        }
      } else if (type === 'rate') {
        response = await store.dispatch('form/putForm', {
          url: `category/on`,
          body: {
            data: {
              tariff_id: service.data.data.info.external_tariff_data.id,
              date_from,
              date_to,
              category_id: service.index,
              price: service.price,
            },
          },
        })
      } else if (type === 'ind') {
        response = await store.dispatch('form/create', {
          url: `individual_price/add/tariff`,
          body: {
            data: [
              {
                id: service.data.data.info.active_coefficient[service.index].id,
                date_from,
                date_to,
                type_id: service.index,
                sum: Number(data.coef),
              },
            ],
          },
        })
      }
      service.data.data.loaded = true
      if (response.code === 1) {
        store.commit('notifies/showMessage', {
          color: 'success',
          content: 'Изменено',
          timeout: 1000,
        })
      } else {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Ошибка сервера',
          timeout: 1000,
        })
      }
      getTargetService(
        services.value.findIndex((x) => x.id === service.data.id),
        true
      )
    }

    // /category/on
    //{
    //   "data": {
    //     "category_id": 98,
    //     "date_from": "2024-12-30",
    //     "date_to": "2024-01-01",
    //     "price": 178.9,
    //     "tariff_id": 449
    //   }
    // }

    const convertData = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (file) => {
      Vue.downloadFile(file)
    }

    onMounted(() => {
      getService()
    })

    watch(
      () => expansion.value,
      (newVal, oldVal) => {
        let index
        if (newVal.length > oldVal.length)
          index = _.difference(newVal, oldVal)[0]
        else index = _.difference(oldVal, newVal)[0]
        if (index !== undefined && !services.value[index]?.data.loaded)
          getTargetService(index)
      },
      { deep: true }
    )

    return {
      expansion,
      loading,
      services,
      rates,
      objectId,
      convertData,
      download,
      changeEntity,
    }
  },
})
export default ObjectTarif
