var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"doljnostItem",style:({
    width: _vm.dense ? '100%' : _vm.styleType === 1 ? '530px' : '500px',
  })},[_c('div',{staticClass:"text--text font-weight-700 text-cut"},[_c('span',{attrs:{"title":_vm.data.name}},[_vm._v(" "+_vm._s(_vm.data.name)+" ")])]),(!_vm.dense)?_c('v-divider',{staticClass:"my-3"}):_vm._e(),(_vm.loading)?[_c('div',{staticClass:"d-flex align-center justify-center",style:({ height: _vm.styleType === 1 ? '453px' : '376px' })},[_c('v-progress-circular',{attrs:{"color":"primary","size":56,"indeterminate":""}})],1)]:(!_vm.doljnostData)?[_c('div',{staticClass:"flex-grow-1 d-flex justify-center align-center"},[_c('plug',{attrs:{"text":"Данные не найдены"}})],1)]:[(_vm.styleType === 1)?_vm._l((_vm.DOLJNOST_STRUCTURE),function(item,index){return _c('div',{key:index,staticClass:"rate"},[(item.title)?_c('div',{staticClass:"text--text font-weight-700 mb-2"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"pt-3 pb-1 px-3",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[(item.subtitle)?_c('div',{staticClass:"w-100 text-center textGray--text font-size-14"},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"overflow-auto d-flex pb-2"},[_vm._l((_vm.RATE_STRUCTURE),function(rate){return _c('div',{key:rate.key,staticClass:"rateItem"},[_c('div',{staticClass:"text--text mb-2"},[_vm._v(_vm._s(rate.title))]),_c('div',{staticClass:"px-2 py-1 primary--text font-weight-700 tag"},[_vm._v(" "+_vm._s(_vm.doljnostData.active_coefficient[index] ? _vm.doljnostData.active_coefficient[index][rate.key] : '-')+" ")])])}),(item.individual)?_c('div',{staticClass:"rateItem"},[_c('div',{staticClass:"text--text mb-2"},[_vm._v("Инд.")]),_c('ChangeMenu',{attrs:{"entity":"tarif","requestData":{
                  entity: 'tarif',
                  id: _vm.doljnostData.active_coefficient[index].id,
                },"single":index},on:{"refresh":function($event){return _vm.getData()}}})],1):_vm._e()],2)]),(item.coef)?_c('div',{staticClass:"mt-2"},[_c('Coefficient',{attrs:{"tooltip":`${_vm.convertData(
              _vm.doljnostData.active_coefficient[index].date_from
            )} - ${_vm.convertData(
              _vm.doljnostData.active_coefficient[index].date_to
            )} `,"coef":{
              [index]: _vm.doljnostData.active_coefficient[index].coefficient,
            },"requestData":{
              entity: 'tarif',
              id: _vm.doljnostData.active_coefficient[index].id,
            },"single":index},on:{"refresh":function($event){return _vm.getData()}}})],1):_vm._e()])}):(_vm.styleType === 2)?[_c('v-row',{staticClass:"justify-space-between"},[_c('span',{staticClass:"secondary--text font-weight-700"},[_vm._v("Тариф")]),_c('span',[_vm._v("zxc")])]),_c('v-row',{staticClass:"pa-3 d-block",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[_c('div',{staticClass:"textGray--text line-normal"},[_vm._v("Внешняя ставка")]),_c('div',{staticClass:"mt-1 secondary--text line-normal"},[_vm._v("База, ₽")]),_c('div',{staticClass:"mt-2 secondary--text font-weight-700 font-size-24 line-normal"},[_vm._v(" "+_vm._s(_vm.doljnostData.external_tariff_data.external_rate)+" ")])]),_c('v-row',_vm._l((_vm.DOLJNOST_STRUCTURE),function(item,index){return (item.subtitle)?_c('div',{key:index,staticClass:"flex-grow-1 pa-3 rateItem tariffItem",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[_c('div',{staticClass:"textGray--text line-normal"},[_vm._v("Внутренняя ставка")]),_c('div',{class:['mt-1', 'secondary--text', 'line-normal']},[_vm._v(" "+_vm._s(item.subtitle)+", ₽ ")]),_c('div',{class:[
              'mt-2',
              index === 1 ? 'primary--text' : 'secondary--text',
              'font-weight-700',
              'font-size-24',
              'line-normal',
            ]},[_vm._v(" "+_vm._s(_vm.doljnostData.active_coefficient[index] ? _vm.doljnostData.active_coefficient[index].base_rate : '-')),(index === 1)?_c('span',{staticClass:"font-weight-400 font-size-14",staticStyle:{"vertical-align":"top"}},[_vm._v("Инд.")]):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('ChangeMenu',{attrs:{"entity":"tarif","requestData":{
                entity: 'tarif',
                id: _vm.doljnostData.active_coefficient[index].id,
              },"single":index,"styleType":2},on:{"refresh":function($event){return _vm.getData()}}})],1),_c('div',{staticClass:"mt-2"},[_c('Coefficient',{attrs:{"tooltip":`${_vm.convertData(
                _vm.doljnostData.active_coefficient[index].date_from
              )} - ${_vm.convertData(
                _vm.doljnostData.active_coefficient[index].date_to
              )} `,"coef":{
                [index]: _vm.doljnostData.active_coefficient[index].coefficient,
              },"requestData":{
                entity: 'tarif',
                id: _vm.doljnostData.active_coefficient[index].id,
              },"single":index,"short":""},on:{"refresh":function($event){return _vm.getData()}}})],1)]):_vm._e()}),0)]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }