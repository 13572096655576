import Vue, {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import exPanel from './../exPanel'
import useRequest from '@/compositions/useRequest'
import Doljnost from './../doljnost'
import Popup from '@/components/Popup/index.vue'
import Coefficient from './../coefficient'
import Tarif from './../tarif'
import versionChange from './../pact/dialog'
import subversionChange from './../pact/version/dialog'
import applicationChange from './dialog'
import plug from './../plug'
import historyChange from '@/pages/object/config/custom-object-tarif/historyChange'

export default defineComponent({
  props: {},
  components: {
    exPanel,
    Popup,
    Doljnost,
    Tarif,
    Coefficient,
    versionChange,
    subversionChange,
    applicationChange,
    historyChange,
    plug,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const dialogTemplate = {
      isShow: false,
      doc: null,
      refresh: null,
      component: null,
    }
    const dialog = ref(_.cloneDeep(dialogTemplate))
    const addVersion = ({ item, component, refresh, contract }) => {
      dialog.value.doc = item
      dialog.value.isShow = true
      dialog.value.refresh = refresh
      dialog.value.component = component
      dialog.value.contract = contract
    }

    const magnitBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Округа', value: 1 },
    ]

    const contractBtn = [
      { name: 'Доп. соглашение', value: 0 },
      { name: 'Приложение', value: 1 },
    ]

    let controller
    const getMagnitType = async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      magnit.value.loading = true
      let response
      try {
        if (magnit.value.docType === 0) {
          response = await store.dispatch('form/getParams', {
            url: 'get/contract/magnit',
            params: {
              signal: controller.signal,
            },
          })
          response.data.forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          magnit.value.items = response.data
        } else if (magnit.value.docType === 1) {
          const list = 'magnit_district'
          response = await store.dispatch('form/update', {
            url: 'get/lists',
            body: [{ alias: list, filter: [] }],
            params: {
              signal: controller.signal,
            },
          })
          response.data[list].forEach((item) => {
            Vue.set(item, 'loaded', false)
            Vue.set(item, 'items', null)
          })
          magnit.value.items = response.data[list]
          if (response.data[list][0])
            magnit.value.activeItems = [response.data[list][0]]
          getActiveFillial()
        }
      } catch (e) {
        return e
      }
      controller = undefined
      magnit.value.loading = false
    }

    const getVersionDocType = async (version) => {
      version.loading = true
      const response = await store.dispatch(
        'form/get',
        `get/contract/versions/${
          version.docType === 0 ? 'additional' : 'application'
        }/${version.id}`
      )
      version.items = response.data
      version.loading = false
    }

    const getFillial = async ({ item, refresh }) => {
      if (item.items && !refresh) return
      item.loaded = true
      const response = await store.dispatch('list/get', [
        {
          alias: 'magnit_filial',
          filter: [
            {
              alias: 'district_id',
              value: [item.id],
            },
          ],
        },
      ])
      response.data.magnit_filial.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      item.items = response.data.magnit_filial
      item.loaded = false
    }

    const getActiveFillial = () => {
      magnit.value.activeItems.forEach((item) => {
        getFillial({ item })
      })
    }

    const magnit = ref({
      items: [],
      loading: false,
      docType: 0,
      activeItems: [],
      lastTarget: 0,
      refresh: getMagnitType,
    })

    const changeSelection = ({
      index,
      btn,
      item,
      activeItems,
      entity,
      items,
      request,
    }) => {
      if (btn === 'shift') {
        if (activeItems.some((x) => x.id === item.id)) {
          if (entity.lastTarget > index) {
            for (
              let i = entity.lastTarget;
              i >= index && activeItems.length > 1;
              i--
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          } else {
            for (
              let i = entity.lastTarget;
              i <= index && activeItems.length > 1;
              i++
            ) {
              activeItems.splice(
                activeItems.findIndex((x) => x.id === items[i].id),
                1
              )
            }
          }
        } else {
          if (entity.lastTarget > index) {
            for (let i = entity.lastTarget; i >= index; i--) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          } else {
            for (let i = entity.lastTarget; i <= index; i++) {
              if (!activeItems.some((x) => x.id === items[i].id)) {
                activeItems.push(items[i])
              }
            }
          }
        }
      } else if (btn === 'ctrl') {
        if (
          activeItems.some((x) => x.id === item.id) &&
          activeItems.length > 1
        ) {
          activeItems.splice(index, 1)
        } else {
          activeItems.push(item)
        }
      } else {
        activeItems.splice(0)
        Vue.set(activeItems, 0, item)
      }
      entity.lastTarget = index
      items.forEach((item) => {
        if (!activeItems.some((x) => x.id === item.id))
          Vue.set(item, 'items', null)
      })
      nextTick(() => request(entity))
    }

    const getSubfillial = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'magnit_subfilial',
          filter: [
            {
              alias: 'filial_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getVersions = async (item) => {
      return await store.dispatch(
        'form/get',
        `get/contract/versions/${item.id}`
      )
    }

    const getFormat = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'magnit_format',
          filter: [
            {
              alias: 'subfilial_id',
              value: [item.id],
            },
            {
              alias: 'filial_id',
              value: [1],
            },
          ],
        },
      ])
    }

    const initFormat = (item) => {
      item.activeItems.push(item.items[0])
      getActiveFormat(item)
    }

    const getActiveFormat = (format) => {
      format.activeItems.forEach((item) => {
        getFormatItem({ item, format })
      })
    }

    const getFormatItem = async ({ item, format, refresh }) => {
      if (item.items && !refresh) return
      item.loaded = true
      const response = await store.dispatch('list/get', [
        {
          alias: 'magnit_object_by_format',
          filter: [
            {
              alias: 'subfilial_id',
              value: [format.id],
            },
            {
              alias: 'format_id',
              value: [item.id],
            },
          ],
        },
      ])
      response.data.magnit_object_by_format.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      item.items = response.data.magnit_object_by_format
      item.loaded = false
    }

    const getObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const formTemplate = {
      isShow: false,
      name: '',
      loading: false,
      maxCount: 255,
      refreshRequest: null,
      entity: {
        type: '',
        id: 0,
      },
      parent: {
        type: '',
        id: 0,
      },
    }
    const form = ref(_.cloneDeep(formTemplate))
    const addEntity = ({ entity, parent }) => {
      form.value = _.cloneDeep(formTemplate)
      form.value.isShow = true
      if (entity.id) {
        form.value.entity.id = entity.id
        form.value.name = entity.name
      }
      form.value.entity.type = entity.type
      if (parent) {
        form.value.parent.id = parent.id
        form.value.parent.type = parent.type
      }
      form.value.refreshRequest = entity.refreshRequest
    }
    const confirmChanges = async () => {
      form.value.loading = true
      try {
        const response = await store.dispatch(
          `form/${form.value.entity.id ? 'putForm' : 'create'}`,
          {
            url: `${form.value.entity.id ? 'update' : 'create'}/${
              form.value.entity.type === 'contract' ? '' : 'magnit_'
            }${form.value.entity.type}`,
            body: {
              data: {
                name: form.value.name,
                id: form.value.entity.id ? form.value.entity.id : undefined,
                [`${form.value.parent.type}_id`]: form.value.parent.id
                  ? form.value.parent.id
                  : undefined,
              },
            },
          }
        )
        if (response.code === 1) {
          form.value.isShow = false
          form.value.refreshRequest()
          store.commit('notifies/showMessage', {
            color: 'success',
            content: 'Сохранено',
            timeout: 1000,
          })
        } else if (response.code === 2) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Ошибка сервера',
            timeout: 1000,
          })
        } else if (response.code === 3) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Невалидные данные',
            timeout: 1000,
          })
        } else if (response.code === 4) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 1000,
          })
        } else if (response.code === 5) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Уже существует',
            timeout: 1000,
          })
        }
      } finally {
        form.value.loading = false
      }
    }

    const refreshPanel = (item) => {
      item.refresh()
    }

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    watch(
      () => magnit.value.docType,
      () => {
        getMagnitType()
      },
      { immediate: true }
    )

    return {
      magnitBtn,
      contractBtn,
      magnit,
      form,
      dialog,

      changeSelection,
      getFillial,
      getSubfillial,
      addEntity,
      refreshPanel,
      getActiveFillial,
      getFormat,
      initFormat,
      getActiveFormat,
      confirmChanges,
      getMagnitType,
      getFormatItem,
      getObject,
      getVersions,
      getVersionDocType,

      addVersion,

      convertDate,
      download,
    }
  },
})
