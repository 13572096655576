import { ref, computed, watch, onMounted, toRef } from 'vue'
import _ from 'lodash'

import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export default {
  name: 'datepicker',
  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number, Object, Array],
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    edge: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {
          outlined: false,
          dense: false,
          hideDetails: false,
          clearable: true,
        }
      },
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const menu = ref(false)
    const mask = computed(() => {
      if (props.field?.subtype === 'multiple') return ''
      else if (props.field?.subtype === 'period') return '####.##'
      return '####.##.##'
    })
    const proxyValue = toRef(props, 'value')
    const dateValue = ref()

    const changeValue = () => {
      if (props.field?.subtype === 'multiple') {
        dateValue.value = _.cloneDeep(proxyValue.value)
        dateValue.value?.forEach((item, index) => {
          dateValue.value[index] = item.replaceAll('.', '-')
        })
      } else {
        dateValue.value = proxyValue.value.replaceAll('.', '-')
        const splitedArr = dateValue.value.split('-')
        if (splitedArr.length === 2 && +splitedArr[1]) {
          dateValue.value = dateValue.value + '-'
        } else if (splitedArr.length === 2 && !+splitedArr[1]) {
          dateValue.value = dateValue.value + '01-'
        } else if (splitedArr.length === 1 && +splitedArr[0]) {
          dateValue.value = dateValue.value + '-01-'
        } else if (splitedArr.length === 1 && !+splitedArr[0]) {
          dateValue.value = new Date().toISOString().split('T')[0]
        }
      }
    }
    const changeMonth = () => {
      console.log('changemonth')
    }
    const changeDate = (year) => {
      if (props.field?.subtype === 'multiple') {
        proxyValue.value = _.cloneDeep(dateValue.value)
        proxyValue.value?.forEach((item, index) => {
          proxyValue.value[index] = item.replaceAll('-', '.')
        })
      } else {
        if (dateValue.value?.includes('-'))
          proxyValue.value = dateValue.value.replaceAll('-', '.')
      }
    }

    const allowDates = (val) => {
      if (props.field?.allowDates) {
        if (props.field?.allowDates?.length) {
          const hasInterval = props.field.allowDates.some((el) => {
            const { date_target_start, date_target_end } = el
            const start = moment(date_target_start, 'YYYY-MM-DD')
            const end = moment(date_target_end, 'YYYY-MM-DD')
            const when = moment(val, 'YYYY-MM-DD')
            const range = moment.range(start, end)
            const result = range.contains(when)
            return result
          })
          if (hasInterval) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return true
      }
    }

    onMounted(() => {
      if (props.field?.subtype === 'multiple') {
        proxyValue.value?.forEach((item, index) => {
          proxyValue.value[index] = item.replaceAll('-', '.')
        })
        dateValue.value = _.cloneDeep(proxyValue.value)
        dateValue.value.forEach((item, index) => {
          dateValue.value[index] = item.replaceAll('.', '-')
        })
      } else {
        if (proxyValue.value?.includes('-'))
          proxyValue.value = proxyValue.value.replaceAll('-', '.')
        if (proxyValue.value?.includes('.'))
          dateValue.value = proxyValue.value.replaceAll('.', '-')
      }
      if (proxyValue.value === '0000.00.00') {
        proxyValue.value = ''
      }
    })
    const pickerDate = ref(null)
    const clearField = () => {}
    watch(
      () => pickerDate.value,
      (val) => {
        if (props.field?.period) props.field.period = val

        emit('changeMonth', {
          field: props.field,
          value: proxyValue.value,
          month: val,
        })
        // proxyValue.value = val
      }
    )
    watch(
      () => proxyValue.value,
      (newVal) => {
        if (props.field?.subtype === 'multiple') {
          proxyValue.value?.forEach((item, index) => {
            proxyValue.value[index] = item.replaceAll('-', '.')
          })
        } else {
          if (proxyValue.value?.includes('-'))
            proxyValue.value = proxyValue.value.replaceAll('-', '.')
        }
        emit('input', newVal)
        emit('change', newVal)
      }
    )

    return {
      menu,
      proxyValue,
      dateValue,
      mask,

      changeDate,
      changeValue,
      clearField,
      changeMonth,
      pickerDate,
      allowDates,
    }
  },
}
