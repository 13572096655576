var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"height-100 py-3 px-3 overflow-y-auto",attrs:{"id":"contractContainer","color":"background","elevation":"0"}},[_c('v-row',{staticClass:"mb-3 justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700"},[_vm._v("Компания")]),_c('div',{staticClass:"d-flex"},[_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{ entity: 'main' },"coef":_vm.mainCoef},on:{"refresh":_vm.refreshType}})],1)]),(!_vm.loading)?[_c('exPanel',{ref:"typeRef",attrs:{"request":_vm.getTerritoriesRequest,"items":_vm.types,"fillActive":"","magnit":"","outlined":""},on:{"getData":_vm.getPanelData},scopedSlots:_vm._u([{key:"header",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"content",fn:function({ item }){return [(item.id === 2)?[_c('magnit',{on:{"openParser":_vm.openParser}})]:(item.id === 12)?[_c('chigik',{on:{"openParser":_vm.openParser}})]:[_c('v-row',{staticClass:"btn-container mb-3"},_vm._l((item.items),function(territory,index){return _c('v-btn',{key:territory.id,class:[
                'btn',
                item.active.includes(index) && 'btn--active',
                'text-none',
                item.loading && 'pointer-none',
              ],attrs:{"min-height":"48px","elevation":"0","color":item.active.includes(index) ? 'primary' : 'text',"outlined":!item.active.includes(index)},on:{"click":[function($event){if(!$event.shiftKey)return null;return _vm.changeTerritory({
                  index,
                  btn: 'shift',
                  type: item,
                })},function($event){if(!$event.ctrlKey)return null;return _vm.changeTerritory({
                  index,
                  btn: 'ctrl',
                  type: item,
                })},function($event){if(!$event.metaKey)return null;return _vm.changeTerritory({
                  index,
                  btn: 'ctrl',
                  type: item,
                })},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeTerritory({
                  index,
                  btn: 'default',
                  type: item,
                })}]}},[_vm._v(" "+_vm._s(territory.name)+" ")])}),1),_c('v-row',[_c('v-btn',{staticClass:"px-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","large":"","min-width":"30"},on:{"click":function($event){return _vm.addEntity({ type: item })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить территорию")],1)],1),_c('v-row',[_c('v-card',{staticClass:"px-1 py-1",attrs:{"elevation":"0"}},_vm._l((_vm.switchBtn),function(btn,btnIndex){return _c('v-btn',{key:btnIndex,class:[
                  item.docType === btnIndex && 'btn--active',
                  'text-none',
                ],attrs:{"min-height":"35px","elevation":"0","color":item.docType === btnIndex ? 'primary' : 'text',"text":item.docType !== btnIndex},on:{"click":function($event){item.docType !== btnIndex && _vm.changeDoc(item, btn.value)}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),1)],1),_vm._l((item.activeTerritories),function(territory){return _c('div',{key:territory.id},[_c('v-row',{staticClass:"my-3 justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":territory.name}},[_vm._v(" "+_vm._s(territory.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":territory.have_individual,"requestData":{ entity: 'territory', id: territory.id }},on:{"refresh":function($event){return _vm.refreshType(item)}}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{ entity: 'territory', id: territory.id },"coef":territory.coefficient},on:{"refresh":function($event){return _vm.refreshType(item)}}})],1)]),_c('v-divider',{staticClass:"mb-3"}),_c('v-btn',{staticClass:"px-3 mb-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","large":"","min-width":"30"},on:{"click":function($event){item.docType === 0
                  ? _vm.addEntity({ type: item, contract: item, territory })
                  : _vm.addEntity({ type: item, zone: item, territory })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить "+_vm._s(item.docType === 0 ? 'договор' : 'зону'))],1),(item.docType === 0)?[(territory.items.length)?_c('Pact',{attrs:{"territory":territory,"data":territory.items},on:{"openParser":_vm.openParser}}):_vm._e()]:(item.docType === 1)?[(territory.items.length)?_c('Zone',{attrs:{"type":item,"territory":territory},on:{"refreshTerritory":_vm.refreshTerritory}}):_vm._e()]:_vm._e(),(!territory.items.length)?_c('plug',{attrs:{"text":`${item.docType === 0 ? 'Документы' : 'Зоны'} не
                найдены`,"icon":item.docType === 0 ? 'mdi-text-box' : 'mdi-map-clock'}}):_vm._e()],2)}),(item.loading)?_c('div',{staticClass:"py-4 d-flex justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1):_vm._e()]]}}],null,false,1021855908)})]:[_c('div',{staticClass:"d-flex height-100 align-center justify-center"},[_c('v-progress-circular',{attrs:{"size":80,"color":"primary","indeterminate":""}})],1)],(_vm.parser)?_c('Popup',{attrs:{"options":{
      width: '1000px',
      portal: 'table-detail',
    }}},[(_vm.parserClone)?_c('v-card',[_c('p',{staticClass:"text-h4 mb-4 pa-4"},[_vm._v("Парсер тарифа")]),_c(_vm.parserClone.type,{tag:"component",attrs:{"tab":_vm.parserClone,"options":_vm.parserClone.config,"stages":_vm.parserClone.stages},on:{"closePopup":function($event){_vm.parser = false}}})],1):_vm._e()],1):_vm._e(),(_vm.form.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[(_vm.form.isShow)?_c('v-card',[_c('p',{staticClass:"text-h4 pt-4 px-4"},[_vm._v("Добавить")]),(_vm.form.component?.render)?_c(_vm.form.component,{tag:"component",on:{"closePopup":function($event){_vm.form.isShow = false},"getItems":function($event){return _vm.refresh(_vm.form.territory)}}}):(_vm.form.component)?_c(_vm.form.component.type,{tag:"component",attrs:{"tab":_vm.form.component,"options":_vm.form.component.config},on:{"getItems":function($event){return _vm.refresh(_vm.form.territory)},"closePopup":function($event){_vm.form.isShow = false}}}):_c('div',{staticClass:"py-4 px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Название договора","clearable":""},model:{value:(_vm.newContract.label),callback:function ($$v) {_vm.$set(_vm.newContract, "label", $$v)},expression:"newContract.label"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('Autocomplete',{attrs:{"field":_vm.autocompleteConfig},model:{value:(_vm.newContract.type),callback:function ($$v) {_vm.$set(_vm.newContract, "type", $$v)},expression:"newContract.type"}})],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":_vm.closeDialog}},[_vm._v("Отменить")]),_c('v-btn',{attrs:{"disabled":!(_vm.newContract.label && _vm.newContract.type),"color":"primary"},on:{"click":_vm.createNewContract}},[_vm._v("Сохранить")])],1)],1)],1):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }