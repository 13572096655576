import Vue, { onMounted, computed, ref, watch, toRef } from 'vue'
import store from '@/store'
import _ from 'lodash'
import Coefficient from './../coefficient'
import Tarif from './../tarif'
import Doljnost from './../doljnost'
import exPanel from './../exPanel'
// import history from '@/pages/object/config/custom-object-tarif/history'

export default {
  name: 'Zone',
  props: {
    type: {
      type: Object,
      default: () => {},
    },
    territory: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Coefficient,
    Tarif,
    // history,
    exPanel,
    Doljnost,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const proxyValue = toRef(props, 'data')
    const cityRef = ref(null)
    const zoneRef = ref(null)
    // const expansion = ref([])

    onMounted(() => {})

    const getCitiesByZone = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'x5_zone_city',
          filter: [
            {
              alias: 'zone_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectByCity = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'object_by_city',
          filter: [
            {
              alias: 'city_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getDoljnostByObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectByZone = async (item) => {
      const response = await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_zone',
          filter: [
            {
              alias: 'zone_id',
              value: [item.id],
            },
          ],
        },
      ])
      Vue.set(item, 'doljnostArray', response.data.active_doljnost_by_zone)
    }

    const refreshObjects = (cityIndex) => {
      cityRef.value.expansion = _.without(cityRef.value.expansion, cityIndex)
      cityRef.value._props.items[cityIndex].loaded = null
      cityRef.value._props.items[cityIndex].items = []
    }

    const refreshCities = (zoneIndex) => {
      zoneRef.value.expansion = _.without(zoneRef.value.expansion, zoneIndex)
      zoneRef.value._props.items[zoneIndex].loaded = null
      zoneRef.value._props.items[zoneIndex].items = []
    }

    // const getVersions = async (index) => {
    //   const version = proxyValue.value[index]
    //   if (version.loaded !== true)
    //     expansion.value = _.without(expansion.value, index)
    //   if (version.loaded !== null) return
    //   version.loaded = false
    //   const response = await store.dispatch(
    //     'form/get',
    //     `get/contract/versions/${version.id}`
    //   )
    //   response.data.forEach((version) => {
    //     Vue.set(version, 'loaded', null)
    //     Vue.set(version, 'items', [])
    //   })
    //   version.loaded = true
    //   version.items = response.data
    //   expansion.value.push(index)
    // }

    // watch(
    //   () => expansion.value,
    //   (newVal, oldVal) => {
    //     let index
    //     if (newVal.length > oldVal.length)
    //       index = _.difference(newVal, oldVal)[0]
    //     else index = _.difference(oldVal, newVal)[0]
    //     if (index !== undefined && !proxyValue.value[index]?.loaded)
    //       getVersions(index)
    //   },
    //   { deep: true }
    // )

    return {
      // expansion,

      proxyValue,
      cityRef,
      zoneRef,
      getCitiesByZone,
      getObjectByCity,
      getDoljnostByObject,
      getObjectByZone,

      refreshObjects,
      refreshCities,
    }
  },
}
